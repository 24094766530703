import { Box, Grid, InputBase, Typography } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { ITableCommandBarProps } from "./interfaces/ITableCommandBarProps";
import React from "react";
import { useSearch } from "../../../../hooks";
import { TableSearchBar } from "../TableSearchBar/TableSearhBar";
import "../../DataTable.css";
import { borderColor } from "@mui/system";
import Card from "@mui/material/Card";

export const TableCommandBar: React.FC<ITableCommandBarProps> = (props) => {
  const { leftItems, rightItems } = props.tableCommandBarProps;
  const { onRowFilteredBySearch, columns, items } = props;

  const renderLeftItems = React.useMemo(() => {
    const plugins = leftItems.plugins;
    const customPlugins = leftItems.customPlugins;
    let leftNodes: React.ReactNode[] = [];

    // Handle plugins
    if (plugins) {
      leftNodes = Object.keys(plugins).map((key) => {
        switch (key) {
          case "leftText":
            const text = plugins.leftText || "";
            const hidden = plugins.hidden ?? false; // Default hidden to false if not provided

            if (hidden) {
              return null; // Don't render if hidden is true
            }

            return (
              <Box
                sx={{ mr: 1, mb: 1 }}
                display="flex"
                justifyContent={"flex-start"}
              >
                <Typography variant="h4" fontWeight="600">
                  {text}
                </Typography>
              </Box>
            );

          default:
            return <></>;
        }
      });
    }

    // Handle customPlugins
    if (customPlugins) {
      customPlugins.forEach((customPlugin) => {
        const hidden = customPlugin.hidden ?? false; // Default hidden to false if not provided

        if (!hidden) {
          leftNodes.push(
            <Box
              sx={{ mr: 1, mb: 1, p: "#48476e" }}
              display="flex"
              justifyContent={"flex-start"}
            >
              {customPlugin.onRender(columns, items)}
            </Box>
          );
        }
      });
    }

    return leftNodes;
  }, [leftItems, columns, items]);

  const renderRightItems = React.useMemo(() => {
    const plugins = rightItems.plugins;
    const customPlugins = rightItems.customPlugins;
    let rightNodes: React.ReactNode[] = [];

    if (plugins) {
      Object.keys(plugins).forEach((key) => {
        switch (key) {
          case "searchField":
            const searchField = plugins.searchField;
            if (searchField) {
              rightNodes.push(
                <Box
                  sx={{ ml: 1, mb: 1 }}
                  display="flex"
                  justifyContent={"flex-start"}
                >
                  <TableSearchBar
                    items={searchField.items}
                    searchKeys={searchField.searchKeys}
                    onRowFilteredBySearch={onRowFilteredBySearch}
                  />
                </Box>
              );
            }
            break;
        }
      });
    }
    if (customPlugins) {
      customPlugins.forEach((customPlugin) => {
        rightNodes.push(
          <Box
            sx={{ ml: 1, mb: 1 }}
            display="flex"
            justifyContent={"flex-start"}
          >
            {customPlugin.onRender(columns, items)}
          </Box>
        );
      });
    }

    return rightNodes;
  }, [rightItems, columns, items]);

  return (
    <Card
      sx={{
        display: "flex",
        flexWrap: "wrap",
        marginBottom: 0,
        border: 0,
        borderRadius: "5px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 3px",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      <Box
        sx={{
          mb: 1,
          flex: 1,
          display: "flex",
          justifyContent: { xs: "center", md: "flex-start" },
          flexWrap: { xs: "wrap", md: "unset" },
        }}
      >
        {renderLeftItems}
      </Box>
      <Box
        sx={{
          mb: 1,
          flex: 1,
          display: "flex",
          justifyContent: { xs: "center", lg: "flex-end" },
          flexWrap: { xs: "wrap", md: "unset" },
        }}
      >
        {renderRightItems}
      </Box>
    </Card>
  );
};
