import { ICommon, initialCommonState } from "./common/types";
import { IComplaintsState, initialComplaintsState } from "./complaints/types";
import { ICustomizerState } from "./customizer/customizerReducer";
import { IDashbaord, initialDashboard } from "./dashboard/types";
import {
  initialOnlineDashboard,
  IOnlineDashbaord,
} from "./onlineDashboard/types";
import { initialPromoState, IPromoState } from "./PromoCode/types";
import {
  initialRegisteredUsersState,
  IRegisteredUsers,
} from "./registeredUsers/types";
import { initialSowDashboard, ISowDashbaord } from "./sowDashboard/types";
import { initialServicesState, IServiceState } from "./sowService/types";

export interface IDropdownListType {
  key: string;
  text: string;
  value: string;
}

export interface IStoreState {
  customizerReducer: ICustomizerState;
  common: ICommon;
  registeredUsers: IRegisteredUsers;
  dashboard: IDashbaord;
  sowDashboard: ISowDashbaord;
  complaints: IComplaintsState;
  promo: IPromoState;
  services: IServiceState;
  onlineDsa: IOnlineDashbaord;
}

export const initialStoreState: IStoreState = {
  customizerReducer: {
    activeDir: "ltr",
    activeNavbarBg: "#0b70fb", // This can be any color,
    activeSidebarBg: "#ffffff", // This can be any color
    activeMode: "light", // This can be light or dark
    activeTheme: "LIGHT_BLUE_THEME", // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME
    SidebarWidth: 240,
  },
  common: initialCommonState,
  registeredUsers: initialRegisteredUsersState,
  dashboard: initialDashboard,
  complaints: initialComplaintsState,
  promo: initialPromoState,
  services: initialServicesState,
  sowDashboard: initialSowDashboard,
  onlineDsa: initialOnlineDashboard,
};
